.footerContainer {
  background: #20212e;
}

.footer {
  padding: 100px 0;
  display: flex;
  justify-content: space-between;
}

.heading {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 133%;

  color: #d0dce7;
  padding-bottom: 12px;
}

.inputContainer {
  display: flex;
  align-items: center;
  max-width: 301px;
  width: 100%;
  padding: 4px 3px;
  border: 1.4px solid rgba(236, 221, 255, 0.15);
  border-radius: 24px;
}
.input {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 143%;
  color: rgba(236, 221, 255, 1);
  padding: 3px 15px;

  width: 100%;
  border: none;
  background: transparent;
  outline: none;
}
.button {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 171%;
  color: #ecddff;
  background: rgba(236, 221, 255, 0.08);
  border-radius: 71px;
  padding: 5px 15px;
  border: none;
  outline: none;
}
.socialContainer {
  padding-top: 70px;
}
.socials {
  display: flex;
  align-items: center;
  gap: 10px;
}
.social {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 1px solid rgba(208, 220, 231, 0.15);
  transition: 0.3s;
}
.social:hover {
  background: rgba(236, 221, 255, 0.08);
}
.marketPlaceAndRegion {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 80px;
}

.marketPlace {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
}
.marketPlace .heading {
  padding-bottom: 0px;
  text-align: left;
}
.text {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: rgb(208, 220, 231, 0.6);
}
.regionContainer {
  max-width: 261px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.regionContainer .heading {
  padding-bottom: 0px;
  text-align: left;
}
.regionContainer .text {
  color: rgb(208, 220, 231, 0.5);
  padding: 20px 0;
}
.dropdown {
  display: flex;
  gap: 8px;
  align-items: center;
  position: relative;
  border: 1.4px solid rgba(208, 220, 231, 0.15);
  border-radius: 23px;
  padding: 7px 15px;
}
.dropDownItems {
  position: absolute;
  top: 25px;
  left: 0;
  min-width: 100%;
  border: 1.4px solid rgba(208, 220, 231, 0.15);
  border-top: none;
  border-radius: 0 0 23px 23px;
  padding: 15px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.dropDownItem {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #d0dce7;
}
.dropDownItems .dropDownItem {
  cursor: pointer;
}
.arrow {
  color: #fff;
  font-size: 16px;
}
.footerBottom {
  padding: 18px 0;
  background: #171822;
}
@media only screen and (max-width: 1199px) {
  .marketPlaceAndRegion {
    gap: 40px;
  }
}
@media only screen and (max-width: 991px) {
  .footer {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
  .heading {
    text-align: center;
  }
  .socialContainer {
    padding-top: 40px;
  }
  .marketPlaceAndRegion {
    width: 80%;
    margin: 0 auto;
    display: grid;
    gap: 50px 20px;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  }
}
@media only screen and (max-width: 767px) {
  .marketPlaceAndRegion {
    width: 100%;
  }
}
@media only screen and (max-width: 520px) {
  .regionContainer {
    grid-column: 1/-1;
  }
}

@media only screen and (max-width: 380px) {
  .marketPlaceAndRegion {
    grid-template-columns: repeat(auto-fit, minmax(133px, 1fr));
  }
}
