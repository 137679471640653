.wrapper {
  display: grid;
  grid-template-columns: 1fr auto 1fr auto 1fr auto 1fr;
  align-items: center;
  gap: 8px;
  color: rgba(236, 221, 255, 0.5);
}
.counterTextWrapper {
  display: grid;
  grid-template-columns: 1fr auto 1fr auto 1fr auto 1fr;
  align-items: center;
  gap: 8px;
  padding-top: 5px;
}
.counterTextWrapper span {
  visibility: hidden;
}
.counterText {
  font-family: var(--fontFamily);

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  color: rgb(236, 221, 255, 0.6);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.count {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;

  text-align: center;

  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  background: #eddfdf;
  border-radius: 12px;
  width: 100%;
}
@media only screen and (max-width: 400px) {
  .counterText {
    font-size: 11px;
  }
}
