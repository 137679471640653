.navbar {
  position: fixed;
  z-index: 5;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 101px;
  background: rgba(23, 24, 34, 0.36);
}
.navs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navItems {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 35px;
}
.navItem {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  text-transform: capitalize;
  color: #ecddff;
}
.navItemActive {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  text-transform: capitalize;
  color: #ecddff;
}
.buttonContainer {
  display: flex;
  align-items: center;
  gap: 15px;
}
.button {
  padding: 10px 25px;
  background: #ecddff;
  border-radius: 14px;
  border: none;
  outline: none;
  cursor: pointer;
}
.buttonText {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  background: linear-gradient(114.79deg, #c040fc -17.76%, #4b3cff 99.88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.hamburger,
.close {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  cursor: pointer;
}
.close {
  position: absolute;
  top: 35px;
  right: 20px;
}
@media only screen and (max-width: 767px) {
  .button {
    padding: 7px 15px;
  }
  .buttonText {
    font-size: 16px;
  }
}
@media only screen and (max-width: 991px) {
  .navItems {
    position: absolute;
    top: 0px;
    right: -150%;
    width: 230px;
    background: rgba(23, 24, 34, 1);
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 100vh;
    padding: 80px 40px;
    padding-bottom: 30px;
    transition: 0.8s ease;
  }
  .sidebar {
    right: 0;
    transition: 0.4s ease;
    z-index: 6;
  }
  .hamburger,
  .close {
    display: block;
  }
}
@media only screen and (max-width: 380px) {
  .logo {
    width: 70px;
  }
  .button {
    padding: 6px 12px;
  }
  .buttonText {
    font-size: 14px;
  }
  .hamburger,
  .close {
    font-size: 24px;
  }
}
