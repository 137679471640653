.card {
  padding: 20px 30px;
  border: 1px solid rgba(207, 219, 213, 0.15);
  border-radius: 16px;
  background: #20212e;
}

.moreContainer {
  display: flex;
  justify-content: flex-end;
  padding: 25px 0;
  padding-top: 15px;
}

.more {
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  cursor: pointer;
}
.image {
  width: 100%;
  border-radius: 10px;
}
.title {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 133%;
  color: #ffffff;
  padding: 7px 0;
  padding-top: 12px;
}
.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
.priceContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}
.icon {
  width: 15.13px;
  height: 22px;
}
.from {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;

  color: rgba(255, 255, 255, 0.5);
}
.price {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;

  color: #ffffff;
  text-transform: uppercase;
}
.flow {
  text-transform: capitalize;
}
.like {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  gap: 5px;
}
.likeIcon {
  font-size: 18px;
  color: #fff;
  cursor: pointer;
}
@media only screen and (max-width: 520px) {
  .card {
    padding: 20px 24px;
  }
}
