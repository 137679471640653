.wrapper {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  padding: 15px;
  background: #30303e;
}

.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 300px;
  width: 100%;
  max-height: 180px;
}

.dragging {
  background-color: #1e1e1e;
  border: 1px dashed rgba(236, 221, 255, 0.8);
}
.icon {
  width: 24px;
  height: 24px;
}
.upLoadedImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.text {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ecddff;
}
.warning {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 140%;
  text-align: center;
  font-feature-settings: "liga" off, "kern" off;

  color: rgba(236, 221, 255, 0.4);
}
.button {
  padding: 10px 25px;
  background: #ecddff;
  border-radius: 14px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.buttonText {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  background: linear-gradient(114.79deg, #c040fc -17.76%, #4b3cff 99.88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.uploadInput {
  display: none;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    padding: 25px;
  }
}
@media only screen and (max-width: 520px) {
  .buttonText {
    font-size: 14px;
  }
}
