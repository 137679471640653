.wrapper {
  padding: 70px 0;
  padding-top: 20px;
}
.container {
  width: 95%;
  max-width: 1280px;
  margin: 0 auto;
}
.allItems {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}

.buttons {
  display: flex;

  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 50px 0;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: 1px solid rgba(207, 219, 213, 0.15);
  border-radius: 18px;
  padding: 12px 20px;
  cursor: pointer;
}
.dateAdded {
  margin-left: auto;
}
.icon {
  width: 16px;
  height: 16px;
}
.text {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;

  color: #ffffff;
}
@media only screen and (max-width: 1320px) {
  .allItems {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 991px) {
  .allItems {
    grid-template-columns: repeat(2, 1fr);
  }

  .buttons {
    justify-content: center;
  }
  .dateAdded {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .allItems {
    max-width: 350px;
    margin: 0 auto;
    width: 100%;
  }
}
